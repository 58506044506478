export class Sort {
  queryId = '';
  index = 0;
  type = 0;
}

export function getSortByQueryId(queryId: string): Sort {
  const sortInfo = localStorage.getItem('sort_info');
  if (sortInfo) {
    const ja = JSON.parse(sortInfo);
    if (ja.length > 0) {
      for (let i = 0; i < ja.length; i++) {
        const item = ja[i];
        if (item.queryId === queryId) {
          return item;
        }
      }
    }
  }
  const sort = new Sort();
  sort.queryId = queryId;
  sort.index = 0;
  sort.type = 2;
  return sort;
}

export function saveSortWithQueryId(
  queryId: string,
  sortIndex: number,
  sortType: number,
) {
  const list: Sort[] = [];
  const sort = new Sort();
  sort.queryId = queryId;
  sort.index = sortIndex;
  sort.type = sortType;
  list.push(sort);
  const sortInfo = localStorage.getItem('sort_info');
  if (sortInfo) {
    const ja = JSON.parse(sortInfo);
    if (ja.length > 0) {
      for (let i = 0; i < ja.length; i++) {
        const item = ja[i];
        if (item.queryId !== queryId) {
          list.push(item);
        }
      }
    }
  }
  console.log('saveSortWithQueryId', list);
  localStorage.setItem('sort_info', JSON.stringify(list));
}

export function checkIfStringEndsWithNumber(str: string): boolean {
  return /\d$/.test(str);
}
