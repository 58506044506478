import React, { useState } from 'react';
import { Flex, Heading, Button, useToast, Text, Link } from '@chakra-ui/react';
import { Link as RouterDomLink } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import CustomInput from '../../components/CustomInput/CustomInput';

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [playTextIndex, setPlayTextIndex] = useState<number>(-1);

  const toast = useToast();
  const textArray = [
    'iconoclasts.',
    'pioneers.',
    'producers.',
    'startups.',
    'merchants.',
  ];

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    if (!userEmail) return;
    try {
      setIsLoading(true);
      setSentEmail(true);
    } catch (error) {
      toast({
        title: (error as Error).message,
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnimationStart = async () => {
    if (playTextIndex < textArray.length - 1) {
      setPlayTextIndex(playTextIndex + 1);
    } else {
      setPlayTextIndex(0);
    }
  };

  return (
    <Flex minWidth={'600px'} flexDirection={'column'} px={20} pt={32}>
      <Text fontSize={'61px'} fontFamily={'Beatrice'} color={'white'} mb={2}>
        Offset Apps
      </Text>
      <Flex>
        <Text
          fontSize={'19px'}
          fontWeight={500}
          fontFamily={'Beatrice'}
          color={'white'}
          mr={1}
        >
          Design-driven technology for wine
        </Text>
        <animated.div
          style={useSpring({
            loop: true,
            from: { opacity: '0' },
            to: { opacity: '4' },
            config: { duration: 3000 },
            onStart: () => handleAnimationStart(),
          })}
        >
          <Text
            fontSize={'19px'}
            fontWeight={500}
            fontFamily={'Beatrice'}
            color={'white'}
          >
            {playTextIndex >= 0 ? textArray[playTextIndex] : ''}
          </Text>
        </animated.div>
      </Flex>
      <Flex mt={16} flexDirection="column" w="380px">
        <Text fontSize={'16px'} color={'white'} mb={5}>
          Forgot your password?
        </Text>
        <Text fontSize={'16px'} color={'white'} mb={6}>
          Don’t worry, it happens to the best of us. Enter your email and we’ll
          send you a link to reset your password. If you do not see it, check
          your SPAM for a message from
          <br />
          accounts@offsetcommerce.com.
        </Text>
        <CustomInput
          type="email"
          placeholder="Email"
          autoFocus={true}
          width={380}
          handleOnChange={(value) => setUserEmail(value)}
        />
        <Button
          isLoading={isLoading}
          backgroundColor="orange.100"
          mt={3}
          fontSize={'14px'}
          fontFamily={'Inter'}
          fontWeight={500}
          height={'48px'}
          onClick={handleSubmit}
        >
          Continue
        </Button>
        <Link
          fontSize={'14px'}
          color={'#F1EFEB'}
          mt={5}
          as={RouterDomLink}
          to={`/login`}
        >
          Never mind, I remember it.
        </Link>
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
