import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Beatrice';
        src: url('/fonts/Beatrice-Light.otf') format('opentype');
        font-weight:  300;
        font-style:   normal;
      }
      @font-face {
        font-family: 'Beatrice';
        src: url('/fonts/Beatrice-LightItalic.otf') format('opentype');
        font-weight:  300;
        font-style:   italic;
      }
      @font-face {
        font-family: 'Beatrice';
        src: url('/fonts/Beatrice-Regular.otf') format('opentype');
        font-weight:  400;
        font-style:   normal;
      }
      @font-face {
        font-family: 'Beatrice';
        src: url('/fonts/Beatrice-RegularItalic.otf') format('opentype');
        font-weight:  400;
        font-style:   italic;
      }
      @font-face {
        font-family: 'Beatrice';
        src: url('/fonts/Beatrice-Medium.otf') format('opentype');
        font-weight:  500;
        font-style:   normal;
      }
      @font-face {
        font-family: 'Beatrice';
        src: url('/fonts/Beatrice-MediumItalic.otf') format('opentype');
        font-weight:  500;
        font-style:   italic;
      }
    `}
  />
);

export default Fonts;
