import {
  getClubs,
  getCustomerSources,
  getCustomerTypes,
  getGroups,
  getReferralCodeList,
  getSubgroupList,
  getTags,
} from './customers';
import { getOrderTypes, getShippingMethods } from './orders';
import { getFormatList, getVarietalList, getWineTypeList } from './products';

export enum EntitiesEnum {
  HOME = 'home',
  CUSTOMERS = 'customer',
  GROUPS = 'group',
  PRODUCTS = 'product',
  ORDERS = 'order',
  PURCHASES = 'purchases',
  ALGOLIA_CUSTOMERS = 'algolia-customer',
  ALGOLIA_ORDERS = 'algolia-order',
  ALGOLIA_PRODUCTS = 'algolia-product',
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly OptionType[];
}

export type OptionType = {
  value: string;
  label: string;
};

export type CustomField = {
  entity: EntitiesEnum;
  key: string;
  type: CustomFieldType;
  asyncOptions?: () => Promise<OptionType[]>;
  label: string;
  options?: OptionType[];
};

export enum CustomFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
  OPTIONS_SINGLE = 'options_single',
  OPTIONS_MULTIPLE = 'options_multiple',
}

const SALUTATION_OPTIONS: OptionType[] = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'Rev.', label: 'Rev.' },
  { value: 'Rabbi', label: 'Rabbi' },
];

const STATE_OPTIONS: OptionType[] = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
  { value: 'IT', label: 'International' },
];

const CUSTOMER_CUSTOM_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'remote_id',
    label: 'ID',
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'tags',
    label: 'Tags',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const userInfo = JSON.parse(localStorage.getItem('token') ?? '');
      const tags = await getTags(userInfo.currentAccount.id);
      return tags
        .map((tag) => ({
          value: ',' + tag.id + ',',
          label: tag.name.toUpperCase(),
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        );
    },
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'active_account',
    label: 'Active Account',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'created',
    label: 'Signup Date',
    type: CustomFieldType.DATE,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "salutation",
  //   label: "Salutation",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: SALUTATION_OPTIONS,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'first_name',
    label: 'First Name',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'last_name',
    label: 'Last Name',
    type: CustomFieldType.TEXT,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "suffix",
  //   label: "Suffix",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'nickname',
    label: 'Nickname',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'email',
    label: 'Email',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'marketing_opt_in',
    label: 'Opt In - Email Marketing',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "email_alt",
  //   label: "Email Alternative",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'first_purchase_date',
    label: 'First Purchase Date',
    type: CustomFieldType.DATE,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'last_order',
    label: 'Last Order Date',
    type: CustomFieldType.DATE,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'last_order_total',
    label: 'Last Order Total',
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'order_count',
    label: 'Order Count',
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'order_total',
    label: 'Lifetime Value',
    type: CustomFieldType.NUMBER,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "legacy_order_total",
  //   label: "Legacy Order Total",
  //   type: CustomFieldType.NUMBER,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'score',
    label: 'Score',
    type: CustomFieldType.NUMBER,
    // type: CustomFieldType.OPTIONS_SINGLE,
    // options: [
    //   { value: '1', label: '1' },
    //   { value: '2', label: '2' },
    //   { value: '3', label: '3' },
    //   { value: '4', label: '4' },
    //   { value: '5', label: '5' },
    //   { value: '6', label: '6' },
    //   { value: '7', label: '7' },
    //   { value: '8', label: '8' },
    //   { value: '9', label: '9' },
    //   { value: '10', label: '10' },
    // ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'clubs',
    label: 'Clubs',
    type: CustomFieldType.OPTIONS_MULTIPLE,
    asyncOptions: async () => {
      const clubs = await getClubs();
      return clubs
        .map((club) => ({
          value: club.id,
          label: club.name,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        );
    },
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "groups",
  //   label: "Groups",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   asyncOptions: async () => {
  //     const groups = await getGroups();
  //     return groups.map((group) => ({
  //       value: group.name,
  //       label: group.name,
  //     }));
  //   },
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'sub_group',
    label: 'Subgroup',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const userInfo = JSON.parse(localStorage.getItem('token') ?? '');
      const subgroupList = await getSubgroupList(userInfo.currentAccount.id);
      return subgroupList.map((element) => ({
        value: element,
        label: element,
      }));
    },
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'customer_type',
    label: 'Customer Type',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const customerTypes = await getCustomerTypes();
      return customerTypes
        .map((element) => ({
          value: element,
          label: element,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        );
    },
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'customer_source',
    label: 'Customer Source',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const customerSources = await getCustomerSources();
      return customerSources
        .map((element) => ({
          value: element,
          label: element,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        );
    },
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'website_source',
    label: 'Website Source',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'referral_source',
    label: 'Referral Source',
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: 'Friend/Colleague', label: 'Friend/Colleague' },
      { value: 'Restaurant/Shop', label: 'Restaurant/Shop' },
      { value: 'Press/Website', label: 'Press/Website' },
      { value: 'Event/Tasting', label: 'Event/Tasting' },
      { value: 'Hotel/Other Winery', label: 'Hotel/Other Winery' },
      { value: 'Social Media', label: 'Social Media' },
      { value: 'Other', label: 'Other' },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "referral_note",
  //   label: "Referral Note",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'referral_code',
    label: 'Referral Code',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const userInfo = JSON.parse(localStorage.getItem('token') ?? '');
      const referralCodeList = await getReferralCodeList(
        userInfo.currentAccount.id,
      );
      return referralCodeList.map((element) => ({
        value: element,
        label: element,
      }));
    },
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'vip_pickup',
    label: 'VIP Pickup',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "credits",
  //   label: "Credits",
  //   type: CustomFieldType.NUMBER,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'birthday',
    label: 'Birthday',
    type: CustomFieldType.DATE,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "phone",
  //   label: "Phone",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "mobile_phone",
  //   label: "Phone - Mobile",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "work",
  //   label: "Phone - Work",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'phone_call_opt_in',
    label: 'Opt In - Phone Call',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'receive_text_messages',
    label: 'Opt In - Text',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "salutation_spouse",
  //   label: "Spouse’s Salutation",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: SALUTATION_OPTIONS,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "first_name_spouse",
  //   label: "Spouse’s First Name",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "last_name_spouse",
  //   label: "Spouse’s Last Name",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "suffix_spouse",
  //   label: "Spouse’s Suffix",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "email_spouse",
  //   label: "Spouse’s Email",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "spouse_phone",
  //   label: "Spouse’s Phone",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "children",
  //   label: "Children",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "pet",
  //   label: "Pets",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "hobbies",
  //   label: "Hobbies",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "interests",
  //   label: "Interests",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "sports",
  //   label: "Sports",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "job_title",
  //   label: "Job Title",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "company",
  //   label: "Company",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "address",
  //   label: "Address",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "address_2",
  //   label: "Address 2",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'city',
    label: 'City',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'state',
    label: 'State',
    type: CustomFieldType.OPTIONS_SINGLE,
    options: STATE_OPTIONS,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'zip',
    label: 'Zip',
    type: CustomFieldType.TEXT,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "billing_address",
  //   label: "Billing Address",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "billing_address_2",
  //   label: "Billing Address 2",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'billing_city',
    label: 'Billing City',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'billing_state',
    label: 'Billing State',
    type: CustomFieldType.OPTIONS_SINGLE,
    options: STATE_OPTIONS,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'billing_zip',
    label: 'Billing Zip',
    type: CustomFieldType.TEXT,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "mailing_company",
  //   label: "Mailing Company",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "mailing_address",
  //   label: "Mailing Address",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "mailing_address_2",
  //   label: "Mailing Address 2",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'mailing_city',
    label: 'Mailing City',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'mailing_state',
    label: 'Mailing State',
    type: CustomFieldType.OPTIONS_SINGLE,
    options: STATE_OPTIONS,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'mailing_zip',
    label: 'Mailing Zip',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'mailing_country',
    label: 'Mailing Country',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'mail_marketing_opt_in',
    label: 'Opt In - Postal Mail',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'mail_marketing_opt_in_update',
    label: 'Opt In - Postal Mail Update',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "special_shipping",
  //   label: "Special Shipping",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "temporary_customer_notes",
  //   label: "To Do",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "alt_admin_notes",
  //   label: "Admin Notes",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "alt_payment_notes",
  //   label: "Payment Notes",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "alt_shipping_notes",
  //   label: "Shipping Notes",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "comments",
  //   label: "Comments",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "custom_message",
  //   label: "Custom Message",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_ip_address",
  //   label: "Signup IP Address",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_user_agent",
  //   label: "Signup User Agents",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: [
  //     { value: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36", label: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36" },
  //     { value: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36", label: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_browser",
  //   label: "Signup Browser",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: [
  //     { value: "Chrome", label: "Chrome" },
  //     { value: "Edge", label: "Edge" },
  //     { value: "Firefox", label: "Firefox" },
  //     { value: "Opera", label: "Opera" },
  //     { value: "Safari", label: "Safari" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_browser_device_type",
  //   label: "Signup Browser Device Type",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: [
  //     { value: "Desktop", label: "Desktop" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_browser_platform",
  //   label: "Signup Browser Platform",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: [
  //     { value: "MacOSX", label: "MacOSX" },
  //     { value: "Windows", label: "Windows" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_browser_version",
  //   label: "Signup Browser Version",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: [
  //     { value: "0.0", label: "0.0" },
  //     { value: "1.0", label: "1.0" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "sc_order_split",
  //   label: "ShipCompliant Order Split",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "crm_customer_record",
  //   label: "ID - CRM Customer Record",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "legacy_customer_id",
  //   label: "ID - Legacy Customer",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'assigned_owner',
    label: 'Assigned Owner',
    type: CustomFieldType.OPTIONS_MULTIPLE,
    options: [
      { value: 'Bryan Haddock', label: 'Bryan Haddock' },
      { value: 'Diddly Boo Boo', label: 'Diddly Boo Boo' },
      { value: 'Jason Lankford', label: 'Jason Lankford' },
      { value: 'Joseph Shipp', label: 'Joseph Shipp' },
      { value: 'Ned Creed', label: 'Ned Creed' },
      { value: 'Some Guy', label: 'Some Guy' },
      { value: 'That Guy', label: 'That Guy' },
      { value: 'Tyson Caly', label: 'Tyson Caly' },
      { value: 'Zachary Stovall', label: 'Zachary Stovall' },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_date",
  //   label: "Deactivation Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_reason",
  //   label: "Deactivation Reason",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_note",
  //   label: "Deactivation Note",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_person",
  //   label: "Deactivation Person",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "reactivation_date",
  //   label: "Reactivation Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "reactivation_note",
  //   label: "Reactivation Note",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "transition_preference",
  //   label: "Transition Preference",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: [],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: 'created_by',
  //   label: 'Account Created By',
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [],
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: 'last_update',
    label: 'Account Last Updated',
    type: CustomFieldType.DATE,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "status",
  //   label: "Status",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [
  //     { value: "Active", label: "Active" },
  //     { value: "Inactive", label: "Inactive" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_email_last_sent",
  //   label: "Signup Email Last Sent",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "agreed_to_mailing_list",
  //   label: "Agreed to Mailing List",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "agreed_to_terms_privacy",
  //   label: "Agreed to Terms Privacy",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "consent_date",
  //   label: "Consent Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "consent_time",
  //   label: "Consent Time",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "created_date",
  //   label: "Created Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "created_time",
  //   label: "Created Time",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "data_vip_pickup",
  //   label: "Data Vip Pickup",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "facebook",
  //   label: "Facebook",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "inactive_date",
  //   label: "Inactive Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "marketing",
  //   label: "Marketing",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "mobile",
  //   label: "Mobile",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "pinterest",
  //   label: "Pinterest",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "primary_phone",
  //   label: "Primary Phone",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "receive_text_message",
  //   label: "Receive Text Message",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "title",
  //   label: "Title",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "twitter",
  //   label: "Twitter",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_facebook",
  //   label: "User Facebook",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_instagram",
  //   label: "User Instagram",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_linkedin",
  //   label: "User Linkedin",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_pinterest",
  //   label: "User Pinterest",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_twitter",
  //   label: "User Twitter",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "work_experience",
  //   label: "Work Experience",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "work_phone",
  //   label: "Work Phone",
  //   type: CustomFieldType.TEXT,
  // },
];

const PRODUCT_CUSTOM_FIELDS: CustomField[] = [
  // {
  //   entity: EntitiesEnum.PRODUCTS,
  //   key: "remote_id",
  //   label: "ID",
  //   type: CustomFieldType.NUMBER,
  // },
  // {
  //   entity: EntitiesEnum.PRODUCTS,
  //   key: "name",
  //   label: "Name",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'purchased',
    label: 'Purchased',
    type: CustomFieldType.OPTIONS_SINGLE,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'not_purchased',
    label: 'Did Not Purchase',
    type: CustomFieldType.OPTIONS_SINGLE,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'wine_type',
    label: 'Wine Type',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const userInfo = JSON.parse(localStorage.getItem('token') ?? '');
      const wineTypeList = await getWineTypeList(userInfo.currentAccount.id);
      return wineTypeList.map((element) => ({
        value: element,
        label: element,
      }));
    },
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'varietal',
    label: 'Varietal',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const userInfo = JSON.parse(localStorage.getItem('token') ?? '');
      const varietalList = await getVarietalList(userInfo.currentAccount.id);
      return varietalList.map((element) => ({
        value: element,
        label: element,
      }));
    },
  },
  // {
  //   entity: EntitiesEnum.PRODUCTS,
  //   key: "sku",
  //   label: "Sku",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'parent_sku',
    label: 'Parent Sku',
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'bottle_size',
    label: 'Format',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const userInfo = JSON.parse(localStorage.getItem('token') ?? '');
      const formatList = await getFormatList(userInfo.currentAccount.id);
      return formatList.map((element) => ({
        value: element,
        label: element,
      }));
    },
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'archived',
    label: 'Archived',
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  // {
  //   entity: EntitiesEnum.PRODUCTS,
  //   key: "vintage",
  //   label: "Vintage",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.PRODUCTS,
  //   key: "country",
  //   label: "Country",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.PRODUCTS,
  //   key: "status",
  //   label: "Status",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [
  //     { value: "Active", label: "Active" },
  //     { value: "Inactive", label: "Inactive" },
  //   ],
  // },
];

const PRODUCT_PURCHASES_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.PRODUCTS,
    key: 'sku',
    label: 'Sku',
    type: CustomFieldType.OPTIONS_SINGLE,
  },
];

const GROUP_CUSTOM_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.GROUPS,
    key: 'remote_id',
    label: 'ID',
    type: CustomFieldType.OPTIONS_MULTIPLE,
    asyncOptions: async () => {
      const groups = await getGroups();
      return groups
        .map((group) => ({
          value: group.id,
          label: group.id,
        }))
        .sort((a, b) => Number.parseInt(a.value) - Number.parseInt(b.value));
    },
  },
  {
    entity: EntitiesEnum.GROUPS,
    key: 'name',
    label: 'Name',
    type: CustomFieldType.OPTIONS_MULTIPLE,
    asyncOptions: async () => {
      const groups = await getGroups();
      return groups.map((group) => ({
        value: group.name,
        label: group.name,
      }));
    },
  },
  {
    entity: EntitiesEnum.GROUPS,
    key: 'status',
    label: 'Status',
    type: CustomFieldType.OPTIONS_MULTIPLE,
    options: [
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
    ],
  },
];

const ORDER_CUSTOM_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.ORDERS,
    key: 'shipping_method_name',
    label: 'Shipping Method',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      return (await getShippingMethods()).map((element) => ({
        value: element,
        label: element,
      }));
    },
  },
  {
    entity: EntitiesEnum.ORDERS,
    key: 'ship_status',
    label: 'Shipping Status',
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: 'Awaiting Pickup', label: 'Awaiting Pickup' },
      { value: 'Cancelled', label: 'Cancelled' },
      { value: 'Completed', label: 'Completed' },
      { value: 'Delivered', label: 'Delivered' },
      { value: 'Delivery exception', label: 'Delivery exception' },
      { value: 'Error', label: 'Error' },
      { value: 'Hold Pickup', label: 'Hold Pickup' },
      { value: 'Hold', label: 'Hold' },
      { value: 'In Transit', label: 'In Transit' },
      { value: 'Not Shipped', label: 'Not Shipped' },
      { value: 'Partially Shipped', label: 'Partially Shipped' },
      { value: 'PaymentAccepted', label: 'PaymentAccepted' },
      { value: 'Picked Up', label: 'Picked Up' },
      { value: 'Processing', label: 'Processing' },
      { value: 'Review', label: 'Review' },
      { value: 'SentToFulfillment', label: 'SentToFulfillment' },
      { value: 'Shipped', label: 'Shipped' },
      { value: 'Tagged', label: 'Tagged' },
      { value: 'To Pick Up', label: 'To Pick Up' },
      { value: 'UnPrinted', label: 'UnPrinted' },
      { value: 'Unshipped', label: 'Unshipped' },
    ],
    // asyncOptions: async () => {
    //   const userInfo = JSON.parse(localStorage.getItem('token') ?? '');
    //   const shipStatusList = await getShipStatusList(
    //     userInfo.currentAccount.id,
    //   );
    //   return shipStatusList.map((element) => ({
    //     value: element,
    //     label: element,
    //   }));
    // },
  },
  // {
  //   entity: EntitiesEnum.ORDERS,
  //   key: "total",
  //   label: "Total",
  //   type: CustomFieldType.NUMBER,
  // },
  {
    entity: EntitiesEnum.ORDERS,
    key: 'sum_total',
    label: 'Sum (Order Total)',
    type: CustomFieldType.NUMBER,
  },
  // {
  //   entity: EntitiesEnum.ORDERS,
  //   key: 'item_count',
  //   label: 'Item count',
  //   type: CustomFieldType.NUMBER,
  // },
  // {
  //   entity: EntitiesEnum.ORDERS,
  //   key: "payment_status",
  //   label: "Payment Status",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.ORDERS,
  //   key: "state",
  //   label: "State",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: STATE_OPTIONS,
  // },
  // {
  //   entity: EntitiesEnum.ORDERS,
  //   key: "city",
  //   label: "City",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.ORDERS,
    key: 'datestamp',
    label: 'Date',
    type: CustomFieldType.DATE,
  },
  {
    entity: EntitiesEnum.ORDERS,
    key: 'order_type',
    label: 'Order Type',
    type: CustomFieldType.OPTIONS_SINGLE,
    asyncOptions: async () => {
      const orderTypes = await getOrderTypes();
      return orderTypes
        .map((element) => ({
          value: element,
          label: element,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        );
    },
  },
];

export enum RuleCondition {
  IS_EMPTY = 'is_empty',
  MATCHES_EXACTLY = 'matches_exactly',
  DOES_NOT_MATCH_EXACTLY = 'does_not_match_exactly',
  IS_PROVIDED = 'is_provided',
  IS_NOT_PROVIDED = 'is_not_provided',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does_not_contain',
  STARTS_WITH = 'starts_with',
  DOES_NOT_START_WITH = 'does_not_start_with',
  ENDS_WITH = 'ends_with',
  DOES_NOT_END_WITH = 'does_not_end_with',
  GREATER_THAN = 'greater_than',
  LESS_THAN = 'less_than',
  GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to',
  LESS_THAN_OR_EQUAL_TO = 'less_than_or_equal_to',
  IS_BETWEEN = 'is_between',
  IS_BEFORE = 'is_before',
  IS_AFTER = 'is_after',
  EQUALS = 'equals',
  DOES_NOT_EQUAL = 'does_not_equal',
  IS_ON_OR_BEFORE = 'is_on_or_before',
  IS_ON_OR_AFTER = 'is_on_or_after',
  IN = 'in',
  NOT_IN = 'not_in',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
  YEAR_TO_DATE = 'year_to_date',
  LAST_12_MONTHS = 'last_12_months',
  LAST_YEAR = 'last_year',
}

export const getConditionsByCustomFieldType = (
  customType: CustomField | undefined,
  fromPurchases: boolean,
): OptionType[] => {
  if (!customType) {
    return [];
  }

  switch (customType.type) {
    case CustomFieldType.TEXT: {
      const array = [
        { value: RuleCondition.IS_EMPTY, label: 'Is Empty' },
        { value: RuleCondition.MATCHES_EXACTLY, label: 'Matches Exactly' },
        {
          value: RuleCondition.DOES_NOT_MATCH_EXACTLY,
          label: 'Does Not Match Exactly',
        },
        { value: RuleCondition.IS_PROVIDED, label: 'Is Provided' },
        { value: RuleCondition.IS_NOT_PROVIDED, label: 'Is Not Provided' },
        { value: RuleCondition.CONTAINS, label: 'Contains' },
        { value: RuleCondition.DOES_NOT_CONTAIN, label: 'Does Not Contain' },
        { value: RuleCondition.STARTS_WITH, label: 'Starts With' },
        {
          value: RuleCondition.DOES_NOT_START_WITH,
          label: 'Does Not Start With',
        },
        { value: RuleCondition.ENDS_WITH, label: 'Ends With' },
        { value: RuleCondition.DOES_NOT_END_WITH, label: 'Does Not End With' },
      ];
      if (fromPurchases) {
        array.splice(0, 1);
      }

      return array;
    }
    case CustomFieldType.NUMBER: {
      const array = [
        { value: RuleCondition.IS_EMPTY, label: 'Is Empty' },
        { value: RuleCondition.MATCHES_EXACTLY, label: 'Matches Exactly' },
        {
          value: RuleCondition.DOES_NOT_MATCH_EXACTLY,
          label: 'Does Not Match Exactly',
        },
        { value: RuleCondition.IS_PROVIDED, label: 'Is Provided' },
        { value: RuleCondition.IS_NOT_PROVIDED, label: 'Is Not Provided' },
        { value: RuleCondition.GREATER_THAN, label: 'Greater Than' },
        { value: RuleCondition.LESS_THAN, label: 'Less Than' },
        {
          value: RuleCondition.GREATER_THAN_OR_EQUAL_TO,
          label: 'Greater Than or Equal To',
        },
        {
          value: RuleCondition.LESS_THAN_OR_EQUAL_TO,
          label: 'Less Than or Equal To',
        },
        { value: RuleCondition.IS_BETWEEN, label: 'Is Between' },
      ];
      if (customType.key.startsWith('sum_')) {
        array.splice(3, 2);
      }
      return array;
    }
    case CustomFieldType.DATE:
      return [
        { value: RuleCondition.IS_EMPTY, label: 'Is Empty' },
        { value: RuleCondition.IS_BEFORE, label: 'Is Before' },
        { value: RuleCondition.IS_AFTER, label: 'Is After' },
        { value: RuleCondition.EQUALS, label: 'Equals' },
        { value: RuleCondition.DOES_NOT_EQUAL, label: 'Does Not Equal' },
        { value: RuleCondition.IS_PROVIDED, label: 'Is Provided' },
        { value: RuleCondition.IS_NOT_PROVIDED, label: 'Is Not Provided' },
        { value: RuleCondition.IS_ON_OR_BEFORE, label: 'Is On or Before' },
        { value: RuleCondition.IS_ON_OR_AFTER, label: 'Is On or After' },
        { value: RuleCondition.IS_BETWEEN, label: 'Is Between' },
        { value: RuleCondition.YESTERDAY, label: 'Yesterday' },
        { value: RuleCondition.LAST_7_DAYS, label: 'Last 7 Days' },
        { value: RuleCondition.LAST_30_DAYS, label: 'Last 30 Days' },
        { value: RuleCondition.THIS_MONTH, label: 'This Month' },
        { value: RuleCondition.LAST_MONTH, label: 'Last Month' },
        { value: RuleCondition.YEAR_TO_DATE, label: 'Year To Date' },
        { value: RuleCondition.LAST_12_MONTHS, label: 'Last 12 Months' },
        { value: RuleCondition.LAST_YEAR, label: 'Last Year' },
      ];
    case CustomFieldType.BOOLEAN:
    case CustomFieldType.OPTIONS_SINGLE:
      if (customType.key.includes('state')) {
        return [
          { value: RuleCondition.MATCHES_EXACTLY, label: 'Is' },
          {
            value: RuleCondition.DOES_NOT_MATCH_EXACTLY,
            label: 'Is Not',
          },
        ];
      } else {
        return [
          { value: RuleCondition.IS_EMPTY, label: 'Is Empty' },
          { value: RuleCondition.MATCHES_EXACTLY, label: 'Matches Exactly' },
          {
            value: RuleCondition.DOES_NOT_MATCH_EXACTLY,
            label: 'Does Not Match Exactly',
          },
        ];
      }
    case CustomFieldType.OPTIONS_MULTIPLE:
      return [
        { value: RuleCondition.IS_EMPTY, label: 'Is Empty' },
        { value: RuleCondition.IN, label: 'In' },
        { value: RuleCondition.NOT_IN, label: 'Not In' },
      ];
  }
};

export function getSpeCustomFieldsByEntity(
  entity: EntitiesEnum,
  fromPurchases: boolean,
): CustomField[] {
  switch (entity) {
    case EntitiesEnum.CUSTOMERS:
      return CUSTOMER_CUSTOM_FIELDS;
    case EntitiesEnum.PRODUCTS:
      if (fromPurchases) {
        return PRODUCT_PURCHASES_FIELDS;
      } else {
        return PRODUCT_CUSTOM_FIELDS;
      }
    case EntitiesEnum.GROUPS:
      return GROUP_CUSTOM_FIELDS;
    case EntitiesEnum.ORDERS:
      return ORDER_CUSTOM_FIELDS;
    default:
      return [];
  }
}

export function getCustomFieldsByEntity(entity: EntitiesEnum): CustomField[] {
  switch (entity) {
    case EntitiesEnum.CUSTOMERS:
      return CUSTOMER_CUSTOM_FIELDS;
    case EntitiesEnum.PRODUCTS:
      return PRODUCT_CUSTOM_FIELDS;
    case EntitiesEnum.GROUPS:
      return GROUP_CUSTOM_FIELDS;
    case EntitiesEnum.ORDERS:
      return ORDER_CUSTOM_FIELDS;
    default:
      return [];
  }
}

export function getMultiSelOptions(
  options: OptionType[],
  value: string,
): OptionType[] | null {
  if (value.length > 0) {
    const array = value.split('|');
    const selOptions = [] as OptionType[];
    array.forEach((s) => {
      options.forEach((option) => {
        if (option.value.toLowerCase() === s.toLowerCase()) {
          selOptions.push(option);
        }
      });
    });
    return selOptions;
  }
  return null;
}
