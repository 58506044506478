import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as RouterDomLink, useHistory } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { judgeUserInfo } from '../../services/auth';
import { EntitiesEnum } from '../../services/queryBuilder';
import { PAGE_INDEX_HOME } from '../../utils/constants';

const QueryCard: React.FC<{
  index: number;
  icon: JSX.Element;
  title: string;
  description: string;
}> = ({ icon, title, description, index }) => {
  return (
    <Flex
      direction="column"
      align="center"
      p={8}
      borderRadius="20px"
      color="white"
      width="260px"
      height="310px"
      bgGradient="linear(to-b, #4A4C5E 0%, #2B2F53 45%)"
    >
      <Box fontSize="40px" mb={4}>
        {icon}
      </Box>
      <Text
        fontFamily="Beatrice"
        fontSize="20px"
        fontWeight="600"
        mb={3}
        lineHeight="29.72px"
      >
        {title}
      </Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="#DDE0FB"
        textAlign="center"
        mb="auto"
        lineHeight="16.94px"
      >
        {description}
      </Text>
      <Link
        as={RouterDomLink}
        to={`/segment/query-results/${index}`}
        width="full"
        _hover={{ textDecoration: 'none' }}
      >
        <Button
          width="full"
          bg="#B95944"
          color="white"
          _hover={{ bg: '#A04F33' }}
          h="44px"
          minH="44px"
          size="none"
          borderRadius="8px"
          fontSize="12px"
          fontFamily="Inter"
          fontWeight="600"
          lineHeight="14.52px"
          padding="0 16px"
        >
          VIEW QUERY
        </Button>
      </Link>
    </Flex>
  );
};

type LandingProps = {
  signOut: () => void;
  setPageIndex: (pageIndex: number) => void;
};

const Landing: React.FC<LandingProps> = ({ signOut, setPageIndex }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const judgeExpired = () => {
    let isExpired = true;
    const loginTimeStr = localStorage.getItem('login_time');
    if (loginTimeStr && loginTimeStr.length > 0) {
      const loginTime = Number.parseInt(loginTimeStr);
      const currentTime = new Date().getTime();
      isExpired = currentTime - loginTime > 3 * 24 * 3600 * 1000;
    }
    if (isExpired) {
      signOut();
    }

    return isExpired;
  };

  useEffect(() => {
    if (!judgeExpired()) {
      if (!judgeUserInfo()) {
        window.location.reload();
      } else {
        setPageIndex(PAGE_INDEX_HOME);
      }
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchQuery.trim()) {
      history.push(
        `/segs/customer/new?nlQuery=${encodeURIComponent(searchQuery.trim())}`,
      );
    }
  };

  const queryCards = [
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Top Customers"
          width="40"
          height="40"
        />
      ),
      index: 1,
      title: 'Top Customers',
      description:
        'High lifetime value (top 10%), frequent purchases, high average order value.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Wine Club Members"
          width="40"
          height="40"
        />
      ),
      index: 2,
      title: 'Wine Club Members',
      description: 'Active wine club membership, segmented by club tier.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Recent Purchasers"
          width="40"
          height="40"
        />
      ),
      index: 3,
      title: 'Recent Purchasers',
      description:
        'Purchased within the last 30 days, regardless of frequency or value.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="High Average Order Value"
          width="40"
          height="40"
        />
      ),
      index: 4,
      title: 'High Average Order Value',
      description: 'Top 20% in average order value across all purchases.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Seasonal Buyers"
          width="40"
          height="40"
        />
      ),
      index: 5,
      title: 'Seasonal Buyers',
      description: 'Historically purchases during key seasons like holidays.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Full Allocation Customers"
          width="40"
          height="40"
        />
      ),
      index: 6,
      title: 'Full Allocation Customers',
      description: 'Taken all of their allocation offers.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Top Allocation Customers"
          width="40"
          height="40"
        />
      ),
      index: 7,
      title: 'Top Allocation Customers',
      description:
        'Have taken all their allocations and requested wish lists items.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Lapsed Customers"
          width="40"
          height="40"
        />
      ),
      index: 8,
      title: 'Lapsed Customers',
      description:
        'No purchase within the last 12+ months but previous activity over a certain threshold.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Local Customers"
          width="40"
          height="40"
        />
      ),
      index: 9,
      title: 'Local Customers',
      description:
        'Within a specific geographic radius of the winery or tasting room.',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Product-Specific Buyers"
          width="40"
          height="40"
        />
      ),
      index: 10,
      title: 'Product-Specific Buyers',
      description:
        'Customers who frequently purchase specific product varietals or types (e.g., reds, whites, Pinot).',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="Gift Purchasers"
          width="40"
          height="40"
        />
      ),
      index: 11,
      title: 'Gift Purchasers',
      description:
        'Customers with purchase history indicating gifts (Order Type=Gift or Gift Message).',
    },
    {
      icon: (
        <img
          src="/top_customers.svg"
          alt="New Customers"
          width="40"
          height="40"
        />
      ),
      index: 12,
      title: 'New Customers',
      description: 'First-time purchasers within the last 1-6 months.',
    },
  ];

  return (
    <Flex direction="column" height="100%" overflow="auto" pr={'30px'}>
      <NavBar
        removeToken={signOut}
        entityType={EntitiesEnum.HOME}
        paramName="home"
      />
      <Box bg="white" borderRadius="4px" p={12} height="100%" overflowY="auto">
        <Flex direction="column" align="center">
          <Box maxW="800px" width="100%">
            <Text
              fontSize="42px"
              fontWeight="700"
              mb={4}
              color="#2B2F53"
              letterSpacing="-0.02em"
              textAlign="center"
              fontFamily="Beatrice"
              lineHeight="62.41px"
            >
              Offset Queries
            </Text>
            <Text
              fontSize="24px"
              color="#2A2F56"
              mb={8}
              fontFamily="Inter"
              fontWeight="400"
              lineHeight="29.05px"
              textAlign="center"
            >
              What Do You Want To Learn About Your Customers Today?
            </Text>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                h="45px"
                pl={4}
                display="flex"
                alignItems="center"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                    stroke="#718096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.5 17.5L13.875 13.875"
                    stroke="#718096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </InputLeftElement>
              <Input
                placeholder="Ask a question..."
                height="45px"
                pl={12}
                borderColor="#E2E8F0"
                borderRadius="12px"
                _focus={{
                  borderColor: '#E2E8F0',
                  boxShadow: 'none',
                }}
                fontSize="16px"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </InputGroup>
          </Box>

          <Box height="57px" />

          <Box width="100%" maxW="1200px" minW="800px" px={6}>
            <Flex
              wrap="wrap"
              mb={12}
              sx={{
                gap: '25px',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                '& > *': {
                  flexShrink: 0,
                },
              }}
            >
              {queryCards.map((card, index) => (
                <QueryCard
                  key={index}
                  index={card.index}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                />
              ))}
            </Flex>

            <Box width="calc(100% - 40px)" mx="auto">
              <Flex
                justify="space-between"
                align="center"
                bg="white"
                p={6}
                borderRadius="12px"
                boxShadow="0 3px 8px -1px rgba(50, 50, 71, 0.05), 0 0 1px rgba(12, 26, 75, 0.24)"
              >
                <Box textAlign="left" flex="1">
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    lineHeight="25px"
                    color="#464969"
                    fontWeight="600"
                    mb={1}
                    textAlign="left"
                  >
                    OR
                  </Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    color="#2A2F56"
                    fontWeight="400"
                    lineHeight="23px"
                    textAlign="left"
                  >
                    Create Your Own Custom Customer Query
                  </Text>
                </Box>
                <Link
                  _hover={{ textDecor: 'none' }}
                  as={RouterDomLink}
                  to={`/segs/${EntitiesEnum.CUSTOMERS}/new`}
                >
                  <Button
                    bg="#B95944"
                    color="white"
                    _hover={{ bg: '#A04F33' }}
                    height="44px"
                    px={6}
                    borderRadius="8px"
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="14.52px"
                    ml={4}
                  >
                    CREATE QUERY
                  </Button>
                </Link>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Landing;
