import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface Account {
  id: string;
  name: string;
  app: string;
  account: string;
}

export interface UserInfo {
  profile: {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    created: string;
    require_2fa: string;
  };
  accounts?: Account[];
  currentAccount?: Account;
  ticket: string;
  token?: string;
  require_2fa?: number;
}

export function useToken() {
  const history = useHistory();

  const [userInfo, setUserInfo] = useState(getLocalUserInfo());

  const saveToken = (userInfo: UserInfo | null) => {
    if (!userInfo) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      setUserInfo(null);
      history.push('/login');
      return;
    }

    localStorage.setItem('token', JSON.stringify(userInfo));
    sessionStorage.setItem('token', JSON.stringify(userInfo));
    setUserInfo(userInfo);
  };

  return {
    setToken: saveToken,
    userInfo,
    token: userInfo?.token,
  };
}

function getLocalUserInfo(): UserInfo | null {
  const tokenString = localStorage.getItem('token');
  if (!tokenString) {
    return null;
  }
  const userInfo = JSON.parse(tokenString) as UserInfo;
  return userInfo || null;
}

function getSessionUserInfo(): UserInfo | null {
  const tokenString = sessionStorage.getItem('token');
  if (!tokenString) {
    return null;
  }
  const userInfo = JSON.parse(tokenString) as UserInfo;
  return userInfo || null;
}

export function judgeUserInfo(): boolean {
  const localUserInfo = getLocalUserInfo();
  const sessionUserInfo = getSessionUserInfo();
  if (
    (localUserInfo?.currentAccount?.id ?? '') !==
    (sessionUserInfo?.currentAccount?.id ?? '')
  ) {
    sessionStorage.setItem('token', JSON.stringify(localUserInfo));
    return false;
  }
  return true;
}

export async function authenticateUserWithCreds(
  email: string,
  password: string,
): Promise<UserInfo> {
  const authenticateResponse = await fetch(`/api/v1/auth/authenticate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: email,
      password,
    }),
  });

  if (!authenticateResponse.ok) {
    throw new Error('Authentication failed');
  }

  const userInfo = (await authenticateResponse.json()) as UserInfo;
  localStorage.setItem('login_time', new Date().getTime().toString());

  return userInfo;
}

export async function authenticateUserWith2FA(
  code: string,
  ticket: string,
): Promise<UserInfo> {
  const authenticateResponse = await fetch(`/api/v1/auth/authenticate/2fa`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pin: code,
      ticket: ticket,
    }),
  });

  if (!authenticateResponse.ok) {
    throw new Error('Authentication failed');
  }

  const userInfo = (await authenticateResponse.json()) as UserInfo;
  localStorage.setItem('login_time', new Date().getTime().toString());

  return userInfo;
}

export async function getUserTokenByAccount(
  ticket: string,
  account: string,
): Promise<string> {
  const authTokenRes = await fetch(
    `/api/v1/auth/authorize?version=` + new Date().getTime(),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ticket: ticket,
        account: account,
      }),
    },
  );

  if (!authTokenRes.ok) {
    throw new Error('Authentication failed');
  }

  const { token } = (await authTokenRes.json()) as {
    token: string;
    account: Account;
  };
  return token;
}
